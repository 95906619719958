import enterCmpCountSrc from '@/assets/image/register.png'
import paymentBalanceCmpCountSrc from '@/assets/image/hasSigned.png'
import hasPayedSrc from '@/assets/image/hasPayed.png'
import waittingForSrc from '@/assets/image/waittingFor.png'
import lockSrc from '@/assets/image/lock.png'
import allAmountSrc from '@/assets/image/allAmount.png'

export const projectStatistics = [{
  src: enterCmpCountSrc,
  key: 'enterCmpCount',
  label: '报名客户数（家）',
  value: 0
}, {
  src: paymentBalanceCmpCountSrc,
  key: 'paymentBalanceCmpCount',
  label: '已缴尾款客户数（家）',
  value: 0
}, {
  src: hasPayedSrc,
  key: 'signCmpCount',
  label: '已签合同客户数（家）',
  value: 0
}, {
  src: waittingForSrc,
  key: 'waitSignCmpCount',
  label: '待商家签署合同数（份）',
  value: 0
}, {
  src: lockSrc,
  key: 'projectEnterQuantity',
  label: '报名锁定总量',
  meterageUnit: '',
  value: 0
}, {
  src: allAmountSrc,
  key: 'projectFullSubscribeQuantity',
  label: '全额认购总量',
  meterageUnit: '',
  value: 0
}]
export const collectionStatus = new Map([[1, ['#F7F7F7', '#999999']], [2, ['#EFF7FF', '#0172EB']], [3, ['#FFEDDC', '#E96114']], [4, ['#EFF7FF', '#0172EB']], [5, ['#E9FEF4', '#0CA41A']], [6, ['#F7F7F7', '#999999']]])

export const showBtn = new Map([[1, ['hasEdit', 'hasRelease', 'hasDelete']], [4, ['hasConfirm', 'hasClose']], [5, ['hasConnect']]])

export const subscribeStatus = new Map([[1, ['#F7F7F7', '#0172EB']], [2, ['#EFF7FF', '#0172EB']], [3, ['#EFF7FF', '#0172EB']], [4, ['#FFEDDC', '#E96114']], [5, ['#FFEDDC', '#E96114']], [6, ['#FFEDDC', '#E96114']], [7, ['#E9FEF4', '#0CA41A']], [8, ['#F7F7F7', '#999999']]])

export const subscribeShowBtn = new Map([[1, ['hasDeposit', 'hasCancel']], [2, ['hasFinalPayment', 'hasCancel']], [3, ['hasFinalPayment']], [4, ['hasSign']], [7, ['hasOrder']]])
