import { postRequest, getRequest } from './index'
import { SCMAPI } from '@/systemConfiguration/index'

// 集采 -----------------------------
// 集采项目列表
export const getCollectionList = (data, successCallback, failureCallback) => {
  postRequest(`${SCMAPI}/v2/centralizedPurchase/project/pageProject`, data, res => (
    successCallback(res)
  ), error => {
    console.log(error)
    if (failureCallback)failureCallback(error)
  })
}
// 获取集采项目详情
export const getCollectionDetail = (id, successCallback, failureCallback) => {
  getRequest(`${SCMAPI}/v2/centralizedPurchase/project/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 集采项目认购明细列表
export const getlistProjectSubscribe = (id, successCallback, failureCallback) => {
  getRequest(`${SCMAPI}/v2/centralizedPurchase/projectSubscribe/listProjectSubscribe/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
export const getSubscribeList = (data, successCallback, failureCallback) => {
  postRequest(`${SCMAPI}/v2/centralizedPurchase/projectSubscribe/pageProjectSubscribe`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 集采认购详情
export const getSubscribeDetail = (id, successCallback, failureCallback) => {
  getRequest(`${SCMAPI}/v2/centralizedPurchase/projectSubscribe/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
