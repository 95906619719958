<template>
  <div class="page-card-demo">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    >
      <el-form-item label="商品类别" label-width="80px">
        <el-cascader
          ref="goodsCategoryRef"
          v-model="formInline.categoryArr"
          placeholder="请选择"
          :options="categoryOption"
          :props="categoryCascadeProps"
          clearable
          @change="categoryChange"
        />
      </el-form-item>
    </FormSearch>
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <template #goodsName="{ row }">
          <span>{{ row.categoryName }}/{{ row.varietyName }}/{{ row.goodsName }}</span>
        </template>
        <template #centralizedPurchaseTotalQuantity="{ row }">
          <span>{{ row.centralizedPurchaseTotalQuantity }}{{ row.meterageUnit }}</span>
        </template>
        <template #projectCompleteOrderTotalQuantity="{ row }">
          <span>{{ row.projectCompleteOrderTotalQuantity }}{{ row.meterageUnit }}</span>
        </template>
        <template #subscribeStatus="{ row }">
          <span v-if="subscribeStatus.has(row.subscribeStatus)" class="status" :style="{background: subscribeStatus.get(row.subscribeStatus)[0],color: subscribeStatus.get(row.subscribeStatus)[1]}">{{ $codeNew(collectionSubscribeStatus, row.subscribeStatus + '') }}</span>
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
  </div>
</template>
<script>
import FormSearch from '@/components/FormSearch.vue'
import Table from '@/components/Table.vue'
import Pagination from '@/components/Pagination2.vue'
import { subscribeStatus } from './menu'
import { getSubscribeList } from '@/api/supplyChain'
import { goodsGetGoodsCategoryList } from '@/api/shoppingMall'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      categoryOption: [],
      // 划级联渲染规则
      categoryCascadeProps: {
        value: 'code',
        label: 'goodsName'
      },
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      listData: [],
      total: 0,
      loading: false,
      subscribeStatus,
      collectionSubscribeStatus: this.$store.getters.getDictionaryItem('SUBSCRIBESTATUS'),
      searchFormItemArr: [
        { type: 'input', label: '集采认购编号', value: 'subscribeNo', width: '100px' },
        { type: 'input', label: '集采项目名称', value: 'projectName', width: '100px' },
        { type: 'input', label: '组织单位', value: 'publishCmpName', width: '100px' },
        { type: 'input', label: '认购单位', value: 'subscribeCmpName', width: '100px' },

        // { type: 'select', label: '商品类别', value: 'goodsName', pLabel: 'dictName', pValue: 'dictId', child: [], width: '80px' },
        { type: 'select', label: '认购状态', value: 'subscribeStatus', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('SUBSCRIBESTATUS'), width: '100px' }
      ],
      itemData: [
        { label: '集采认购编号', prop: 'subscribeNo', width: 200 },
        { label: '组织单位', prop: 'publishCmpName', width: 200 },
        { label: '认购单位', prop: 'subscribeCmpName', width: 200 },
        { label: '集采项目名称', prop: 'projectName', width: 180 },
        { label: '商品类别', prop: 'goodsName', width: 220 },
        { label: '认购数量/单位', prop: 'enterQuantity', width: 180 },
        { label: '集采单价(元)', prop: 'centralizedPurchasePrice', width: 140 },
        { label: '尾款截止日', prop: 'balancePaymentEndDate', width: 140 },
        { label: '集采终止日', prop: 'projectEndDate', width: 140 },
        { label: '报名时间', prop: 'createTime', width: 160 },
        { label: '认购状态', prop: 'subscribeStatus', width: 120, child: this.$store.getters.getDictionaryItem('SUBSCRIBESTATUS') }
      ],
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.detailSubscribe }
      ]
    }
  },
  created() {
    // 获取产品类别
    this.getCategoryList()
  },
  methods: {
    // 级联change事件
    categoryChange(val) {
      if (val.length !== 0) {
        this.formInline.categoryId = val[0]
        this.formInline.varietyId = val[1]
        this.formInline.goodsId = val[2]
      } else {
        this.formInline.categoryId = ''
        this.formInline.varietyId = ''
        this.formInline.goodsId = ''
      }
    },
    // 品类 级联选择器数据
    getCategoryList() {
      goodsGetGoodsCategoryList(res => {
        this.categoryOption = res.data
        this.changeCategoryList(this.categoryOption)
      })
    },
    // 递归删除空子级
    changeCategoryList(data) {
      data.map((item) => {
        this.changeCategoryList(item.children)
        if (item.children.length === 0) {
          delete item.children
        }
      })
    },
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1,
          categoryArr: []
          // settleBankId: this.$route.query.settleBankId || '',
          // cmpId: JSON.parse(localStorage.getItem(`mallBackstageUserInfo${this.$store.state.userCmpRole}`)).cmpId
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      getSubscribeList(this.formInline, res => {
        const meterageUnitOptions = this.$store.getters.getDictionaryItem('purchaseUnit')
        this.listData = res.data.records.map(item => {
          item.balancePaymentEndDate = `${item.balancePaymentEndDate} 23:59`
          item.projectEndDate = `${item.projectEndDate} 23:59`
          item.createTime = item.createTime.slice(0, -3)
          const obj = meterageUnitOptions.find(val => val.dictId === item.meterageUnit) || {}
          item.enterQuantity = item.enterQuantity + obj.dictName
          return item
        })
        this.total = res.data.total
      })
    },
    detailSubscribe(row) {
      this.$router.push({
        path: '/collectionSubscribe/collectionSubscribeDetail',
        query: {
          projectId: row.id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.status {
  padding: 4px;
  border-radius: 4px;
}
</style>
